<template>
  <div>
    <v-card flat class="my-0 py-0">
      <v-card-text class="my-0 py-0">
        <v-row justify="end" class="mb-2 pt-1">
          <!-- <v-btn
            text
            color="primary"
            style="margin-top: 15px"
            v-if="bulkUpdateArray && bulkUpdateArray.length > 0"
            @click="bulkUpdateModal = true"
          >
            <v-icon>update</v-icon> Bulk Update
          </v-btn> -->
          <v-col cols="12" sm="4" md="3" class="text-center mb-0 pb-0">
            <v-list-item
              @click="documentUserAssign = true"
              :key="documentUserKey"
            >
              <v-list-item-action>
                <v-icon color="grey" v-if="!shipment.assignedDocumentUser">
                  person
                </v-icon>
                <v-avatar
                  v-else
                  :color="
                    shipment.assignedDocumentUser.avatar ? 'white' : 'secondary'
                  "
                >
                  <v-img
                    v-if="shipment.assignedDocumentUser.avatar"
                    :src="shipment.assignedDocumentUser.avatar"
                    contain
                  ></v-img>
                  <h3 v-else>
                    {{ shipment.assignedDocumentUser.firstname.charAt(0) }}
                  </h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content class="text-left">
                <v-list-item-title>
                  {{
                    getDocumentUserDescription(shipment.assignedDocumentUser)
                  }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="shipment.assignedDocumentUserId">
                  Assigned Documentation User
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-autocomplete rounded prepend-inner-icon="location_on" label="Document Processing Office" v-model="shipment.documentProcessingAddressId" @change="updateDocumentationAddress()"
                :items="addresses" outlined dense item-text="alias" item-value="id"></v-autocomplete> -->
          </v-col>
          <v-col cols="12" sm="4" md="3" class="text-center mb-0 pb-0">
            <v-list-item @click="documentProcessingOfficeModal = true">
              <v-list-item-action>
                <v-icon color="grey"> location_on </v-icon>
              </v-list-item-action>
              <v-list-item-content class="text-left">
                <v-list-item-title>
                  {{ getAddressAlias(shipment.documentProcessingAddressId) }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="shipment.documentProcessingAddressId"
                >
                  Document Processing Office
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-autocomplete rounded prepend-inner-icon="location_on" label="Document Processing Office" v-model="shipment.documentProcessingAddressId" @change="updateDocumentationAddress()"
                :items="addresses" outlined dense item-text="alias" item-value="id"></v-autocomplete> -->
          </v-col>
          <v-col cols="12" sm="4" md="4" class="text-right mb-0 pb-0">
            <v-chip>
              <v-tooltip
                top
                v-if="shipment.shipmentFile && shipment.shipmentFile.fileCover"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    v-on="on"
                    @click="previewFileCoverModal = true"
                  >
                    <v-icon small>article</v-icon>
                  </v-btn>
                </template>
                View File Cover
              </v-tooltip>

              <v-tooltip top v-else>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    v-on="on"
                    id="generate-file-cover-document-button"
                    :loading="generatingFileCover"
                    @click="generateCover()"
                  >
                    <v-icon small>post_add</v-icon>
                  </v-btn>
                </template>
                Generate File Cover
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    v-on="on"
                    id="add-document-button"
                    @click="addManualDocument()"
                  >
                    <v-icon small>add_circle_outline</v-icon>
                  </v-btn>
                </template>
                Add document manually
              </v-tooltip>
              <v-btn small icon @click="infoStartGuide">
                <v-icon small>help</v-icon>
              </v-btn>
              <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
              ></v-text-field>
            </v-chip>
          </v-col>
        </v-row>
        <v-data-table
          id="documents-table"
          :key="documentUpdateKey"
          :items="documents"
          :loading="loadingDocuments"
          :headers="headers"
          :search="search"
          fixed-header
          flat
          disable-pagination
          dense
          hide-default-footer
          sort-by="locationDocument.rank"
          :group-by="groupedHeader"
          height="60vh"
          class="elevation-0 py-0 my-0"
          style="cursor: pointer; box-shadow: none !important"
        >
          <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
            <th colspan="12">
              <v-icon @click="toggle" small class="mr-1"
                >{{ isOpen ? "remove" : "add" }}
              </v-icon>
             

              <!-- <v-chip small icon class="mx-1"><v-icon left color="yellow" small>star</v-icon>Rank: {{ items[0].locationDocument.rank }}</v-chip> -->

              <span v-if="items[0].locationDocument.parentTypeDocument">{{
                items[0].locationDocument.parentTypeDocument.name
              }}</span>
              <span v-else>{{ items[0].locationDocument.name }}</span>
              <i class="mx-1">{{ items.length }} item(s)</i>
              <v-tooltip top v-if="findMassAction(items) != null">
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    :loading="startingAction"
                    color="primary"
                    
                    class="mx-1"
                    @click="startMassAction(findMassAction(items))"
                    ><v-icon >category</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px"
                  >Mass Action: {{ findMassAction(items).name }}</span
                >
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  items[0].locationDocument.name === 'Packing List' &&
                  items[0].bookingContainer
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    style="text-transform: none"
                    icon
                    v-if="
                      items[0].locationDocument.name === 'Packing List' &&
                      items[0].bookingContainer
                    "
                    
                    color="primary"
                    class="mx-1"
                    @click="openPackingListDialog(items)"
                  >
                    <v-icon >mail</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px"
                  >Mass Action: Email Packing List</span
                >
              </v-tooltip>
              <!-- <v-chip small class="mx-1"></v-chip> -->
            </th>
          </template>
          <!-- <template v-slot:[`item.bulkUpdate`]="{ item }">
            <v-row justify="center" align="center">
              <v-checkbox dense
                v-if="checkBulkDateStatus(item)"
                v-model="bulkUpdateArray" :value="item"></v-checkbox>
            </v-row>
          </template> -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn-toggle borderless :max="0" multiple :value="[]">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    @click="viewDocument(item)"
                  >
                    <v-icon small>open_in_new</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px">View Document Details</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="tableBillableStates[item.id]"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    @click="updateDocumentBillable(item)"
                  >
                    <v-icon small :color="item.isBillable ? 'green' : 'grey'">{{
                      item.isBillable ? "paid" : "attach_money"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px">Change Billable Status</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="tableDeleteStates[item.id]"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    color="red"
                    @click="deleteShipmentDocumentType(item)"
                  >
                    <v-icon small color="red">delete</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px">Delete Document</span>
              </v-tooltip>
            </v-btn-toggle>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="getStatusColor(item.status)"
                  >{{ getStatusIcon(item.status) }}</v-icon
                >
              </template>
              <span style="font-size: 12px">{{ item.status }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.integrationStatus`]="{ item }">
            <v-tooltip
              top
              :key="documentUpdateKey"
              v-if="item.integrationStatus"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="getStatusColor(item.integrationStatus)"
                  >{{ getStatusIcon(item.integrationStatus) }}</v-icon
                >
              </template>
              <span style="font-size: 12px">{{ item.integrationStatus }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.locationDocument.name`]="{ item }">
            <v-tooltip top v-if="item.manuallyAdded">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small color="blue"
                  >verified</v-icon
                >
              </template>
              <span style="font-size: 12px">Manually added document</span>
            </v-tooltip>
            <v-tooltip top v-if="item.comment">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small color="blue"
                  >description</v-icon
                >
              </template>
              <span style="font-size: 12px">Notes: {{ item.comment }}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.specialInstruction">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small color="yellow"
                  >star</v-icon
                >
              </template>
              <span style="font-size: 12px"
                >Special Instructions: {{ item.specialInstruction }}</span
              >
            </v-tooltip>
            <v-tooltip top v-if="item.attest">
              <template v-slot:activator="{ on }">
                <v-icon small color="orange" v-on="on">rule</v-icon>
              </template>
              <span style="font-size: 12px"> Attest </span>
            </v-tooltip>
            <v-tooltip top v-if="item.approvalRequired">
              <template v-slot:activator="{ on }">
                <v-icon small color="orange" v-on="on">warning</v-icon>
              </template>
              <span style="font-size: 12px"> Approval Required </span>
            </v-tooltip>

            {{ item.locationDocument.name }}
            <v-tooltip top v-if="item.attestRequired">
              <template v-slot:activator="{ on, attrs }">
                <i v-bind="attrs" v-on="on" style="color: red"
                  >(Attest Required)</i
                >
              </template>
              <span style="font-size: 12px"> </span>
            </v-tooltip>
          </template>

          <template v-slot[`item.tasks`]="{ item }">
            <v-chip small v-if="documentTasks(item).length > 0">
              {{ documentTasks(item).length }}
            </v-chip>
          </template>
          <template v-slot:[`item.submittedDue`]="{ item }">
            <span
              v-if="item.submittedDue && !item.submittedDate"
              :style="{
                color:
                  new Date(item.submittedDue) < new Date()
                    ? 'orange'
                    : 'primaryText',
              }"
            >
              {{ item.submittedDue }}
            </span>
            <span
              v-else-if="item.submittedDue"
              style="color: var(--v-success-base)"
              >{{ item.submittedDue }}</span
            >
          </template>
          <template v-slot:[`item.completedDue`]="{ item }">
            <span
              v-if="item.completedDue && !item.completedDate"
              :style="{
                color:
                  new Date(item.completedDue) < new Date()
                    ? 'orange'
                    : 'primaryText',
              }"
            >
              {{ item.completedDue }}
            </span>
            <span
              v-else-if="item.completedDue"
              style="color: var(--v-success-base)"
              >{{ item.completedDue }}</span
            >
          </template>

          <template v-slot:[`item.media`]="{ item }">
            <v-chip small v-if="documentMedia(item).length > 0">
              {{ documentMedia(item).length }}
            </v-chip>
          </template>
          <template v-slot:[`item.responsiblePartyType`]="{ item }">
            <v-menu
              transition="scale-transition"
              v-if="item.responsiblePartyType"
            >
              <template v-slot:activator="{ on: menu, attrs: menuAttrs }">
                <v-tooltip top>
                  <template
                    v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }"
                  >
                    <v-btn
                      v-bind="{ ...menuAttrs, ...tooltipAttrs }"
                      v-on="{ ...menu, ...tooltip }"
                      icon
                      outlined
                      small
                      class="mx-1"
                    >
                      <v-icon small>{{
                        getIcon(item.responsiblePartyType)
                      }}</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 12px; text-transform: capitalize">{{
                    item.responsiblePartyType
                  }}</span>
                </v-tooltip>
              </template>
              <v-card>
                <v-list>
                  <v-list-item
                    v-for="party in documentResponsibleParties"
                    :key="party.id"
                    @click="setDocumentResponsibleParty(party.value, item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ party.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </template>
          <template v-slot:[`item.approvedCount`]="{ item }">
            <v-chip
              small
              :color="
                item.approvedCount >= item.requiredCount ? 'green' : 'darkgrey'
              "
              class="white--text"
            >
              {{ item.approvedCount }}
            </v-chip>
          </template>

          <template v-slot:[`item.quantity`]="{ item }">
            <v-tooltip top v-if="item.quantityType == 'Shipment'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  outlined
                  small
                  class="mx-1"
                  v-on="on"
                >
                  <v-icon small>directions_boat</v-icon>
                </v-btn>
              </template>
              <span style="font-size: 12px; text-transform: capitalize">{{
                item.quantityType
              }}</span>
            </v-tooltip>

            <v-tooltip top v-else-if="item.quantityType == 'Container'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  outlined
                  small
                  class="mx-1"
                  v-on="on"
                  color="orange"
                >
                  <v-icon small>widgets</v-icon>
                </v-btn>
              </template>
              <span style="font-size: 12px; text-transform: capitalize">{{
                item.quantityType
              }}</span>
            </v-tooltip>

            <v-tooltip top v-else-if="item.quantityType == 'CountContainer'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  outlined
                  small
                  class="mx-1"
                  v-on="on"
                  color="blue"
                >
                  <v-icon small>view_module</v-icon>
                </v-btn>
              </template>
              <span style="font-size: 12px; text-transform: capitalize">
                Per {{ item.count }} Containers</span
              >
            </v-tooltip>
          </template>

          <template v-slot:[`item.courier`]="{ item }">
            <v-icon v-if="item.courier" color="success">check</v-icon>
          </template>
          <template v-slot:[`item.bookingContainer.containerNo`]="{ item }">
            <span v-if="item.bookingContainer">{{
              item.bookingContainer.containerNo
            }}</span>
            <v-chip small v-else-if="item.quantityType != 'Shipment'">{{
              item.shipmentDocumentTypeContainers.length
            }}</v-chip>
            <v-chip small outlined v-else>All</v-chip>
          </template>
          <template v-slot:[`item.completedDate`]="{ item }">
            <span v-if="item.status == 'Complete'">
              {{ item.completedDate }}
            </span>
          </template>
          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-btn-toggle borderless :max="0" multiple :value="[]">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon small @click="viewDocument(item)">
                    <v-icon small>open_in_new</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px">View Document Details</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="tableBillableStates[item.id]" v-bind="attrs" v-on="on" icon small @click="updateDocumentBillable(item)">
                    <v-icon small :color="item.isBillable ? 'green' : 'grey'">attach_money</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px">Change Billable Status</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="tableDeleteStates[item.id]" v-bind="attrs" v-on="on" icon small color="red" @click="deleteShipmentDocumentType(item)">
                    <v-icon small color="red">delete</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 12px">Delete Document</span>
              </v-tooltip>
            </v-btn-toggle>
          </template> -->
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="documentModal" fullscreen>
      <div v-if="documentItem && documentItem.id">
        <DocumentModal
          :documentUpdateKey="documentUpdateKey"
          :openKey="openKey"
          @close="documentModal = false"
          :item="shipment"
          :actions="actions"
          :bookings="bookings"
          :documentItem="documentItem"
          :userScope="userScope"
          :tasks="documentTasks(documentItem)"
          :documents="documentTypes"
          :documentTypes="documentTypes"
          :relationships="relationships"
          :uploads="uploads"
          :shipment="shipment"
          @updateDocument="updateDocument"
          :fileNumber="fileNumber"
          @getShipmentDocuments="getShipmentDocuments"
          @refreshDocument="refreshDocument"
        />
      </div>
    </v-dialog>

    <v-dialog
      v-model="taskModal"
      width="1200px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <TaskModal
        :task="task"
        :shipment="shipment"
        :bookings="bookings"
        @updateDocument="updateDocument"
        :actions="actions"
        :documents="uploads"
        :documentTypes="documentTypes"
        @close="(taskModal = false), (task = {})"
        :documentUpdateKey="documentUpdateKey"
        @update="update"
        :statusKey="taskUpdateKey"
        :fileNumber="fileNumber"
        @refreshAttachments="getTaskAttachments"
      />
    </v-dialog>

    <v-dialog
      v-model="formPreview"
      width="750px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <FormModal
        v-if="form.form"
        :form="form.form"
        :key="formKey"
        @close="formPreview = false"
        @submit="submitForm"
      />
    </v-dialog>

    <v-dialog v-model="submittedDateModal" persistent width="290px">
      <v-date-picker v-model="documentItem.submittedDate" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="submittedDateModal = false">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          :loading="savingDate"
          @click="updateSubmissionDate"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-dialog v-model="completedDateModal" persistent width="290px">
      <v-date-picker v-model="documentItem.completedDate" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="completedDateModal = false">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          :loading="savingDate"
          @click="updateCompletedDate"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog
      v-model="previewModal"
      :fullscreen="$vuetify.breakpoint.mobile || fullscreen"
      width="1200px"
    >
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
            {{ previewItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="fullscreen = !fullscreen" icon
            ><v-icon v-if="!fullscreen">fullscreen</v-icon
            ><v-icon v-else>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon @click="(previewModal = false), (previewItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text
          :style="{
            'max-height': fullscreen ? '88vh' : '75vh',
            'overflow-y': 'auto',
            'background-color': 'var(--v-modal-base) !important',
          }"
        >
          <v-row justify="center">
            <v-col cols="12" md="9" lg="8" xl="6" class="text-center">
              <div ref="pdfBox">
                <pdf
                  style="display: none"
                  :src="previewItem.fullFile"
                  @num-pages="pageCount = new Array($event)"
                />
                <pdf
                  v-for="(page, index) in pageCount"
                  :key="index"
                  :src="previewItem.fullFile"
                  :page="index + 1"
                  class="contract-container"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addAttachmentModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          Add Attachment
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="(addAttachmentModal = false), (attachmentItem = {})"
            >X</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            label="Select Document Type"
            item-text="name"
            item-value="id"
            :items="availableAttachmentDocuments()"
            v-model="attachmentItem.taskAttachment"
            outlined
            dense
            return-object
          ></v-autocomplete>
          <v-autocomplete
            label="Select Uploaded Document"
            item-text="name"
            item-value="key"
            :items="uploads"
            v-model="attachmentItem.file"
            outlined
            dense
          ></v-autocomplete>
          <v-checkbox
            v-if="
              attachmentItem.taskAttachment &&
              attachmentItem.taskAttachment.attestOption
            "
            label="Attest Document"
            v-model="attachmentItem.taskAttachment.attest"
          ></v-checkbox>
          <v-row class="mt-2" justify="center">
            <v-btn
              text
              color="primary"
              @click="saveAttachment"
              :loading="savingDocumentAttachment"
              >Save</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="serviceFailureModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent"
          ><v-toolbar-title>Service Failure</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            icon
            :loading="savingServiceFailure"
            :disabled="
              !serviceFailureItem.serviceFailureCategoryId ||
              (!serviceFailureItem.responsibleOrganisationId &&
                !serviceFailureItem.responsibleParty)
            "
            @click="saveServiceFailure"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn
            text
            @click="(serviceFailureModal = false), (serviceFailureItem = {})"
            >X</v-btn
          ></v-toolbar
        >
        <v-card-text>
          <v-autocomplete
            label="Category*"
            :items="serviceFailureCategories"
            v-model="serviceFailureItem.serviceFailureCategoryId"
            outlined
            dense
            clearable
            item-text="name"
            item-value="id"
          ></v-autocomplete>
          <v-autocomplete
            :items="availableParties"
            v-model="serviceFailureItem.responsibleOrganisationId"
            label="Responsible Party"
            outlined
            dense
            clearable
            item-text="name"
            item-value="value"
          ></v-autocomplete>
          <v-text-field
            v-if="!serviceFailureItem.responsibleOrganisationId"
            v-model="serviceFailureItem.responsibleParty"
            outlined
            dense
            clearable
            label="Specify Party"
          ></v-text-field>
          <!-- <v-select outlined dense label="Responsible Party" :items="responsibleParties" v-model="serviceFailureItem.responsibleParty" clearable></v-select> -->
          <v-textarea
            outlined
            rows="8"
            v-model="serviceFailureItem.comment"
            placeholder="Description"
          ></v-textarea>
          <v-row class="mt-2" justify="center">
            <v-btn
              @click="deleteServiceFailure()"
              :loading="deletingServiceFailure"
              v-if="
                serviceFailureItem.id &&
                serviceFailureItem.organisationId == $store.state.currentOrg.id
              "
              color="red"
              style="text-transform: none"
              ><v-icon>delete</v-icon> Delete</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addDocumentModal"
      width="1400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title v-if="!manualDocument || !manualDocument.id"
            >Select a Document</v-toolbar-title
          >
          <v-toolbar-title v-else>{{ manualDocument.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(addDocumentModal = false), (manualDocument = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text v-if="!manualDocument || !manualDocument.id">
          <v-row justify="end">
            <v-col cols="12" md="6">
              <v-text-field
                label="Search"
                prepend-inner-icon="search"
                v-model="searchAdditionalDocuments"
                outlined
                dense
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            style="cursor: pointer"
            :loading="loadingAdditionalDocuments"
            :items="countryDocuments"
            :headers="additionalDocumentHeaders"
            :search="searchAdditionalDocuments"
            @click:row="selectManualDocument"
          >
          <template v-slot:[`item.iso2`]="{ item }">
            <v-avatar size="32" >
                                            <v-img contain v-if="item.iso2" :src="`https://cdn.loglive.io/flags/4x3/${item.iso2
                                                .substring(0, 2)
                                                .toLowerCase()}.svg`"></v-img> </v-avatar>
                    </template>
        </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectVariationModal"
      width="600px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="manualDocument && manualDocument.subtypes">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Select Variation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="selectVariationModal = false" text>X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item
              @click="setSubtype(subtype)"
              v-for="subtype in manualDocument.subtypes"
              :key="subtype.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ subtype.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="setQuantityModal"
      width="600px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="manualDocument && manualDocument.locationDocument">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Quantity</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="setQuantityModal = false" text>X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item
              v-if="manualDocument.parentShipmentDocument"
              @click="selectVariationModal = true"
            >
              <v-list-item-action>
                <v-icon>description</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  manualDocument.parentShipmentDocument.name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  >Variation:
                  {{
                    manualDocument.locationDocument.name
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-action>
                <v-icon>description</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  manualDocument.locationDocument.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-card
              outlined
              @click="(manualDocument.quantityType = 'Shipment'), typeKey++"
              class="text-center mt-2"
              style="border: 1px solid orange; border-radius: 20px"
            >
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="orange">directions_boat</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    Per Shipment
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="typeKey">
                  <v-icon
                    color="success"
                    v-if="manualDocument.quantityType == 'Shipment'"
                    >check_circle_outline</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
            </v-card>

            <v-card
              outlined
              @click="(manualDocument.quantityType = 'Container'), typeKey++"
              class="text-center mt-2"
              style="border: 1px solid teal; border-radius: 20px"
            >
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="teal">widgets</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    {{ item.breakBulkShipment ? "Per CTO" : "Per Container" }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="typeKey">
                  <v-icon
                    color="success"
                    v-if="manualDocument.quantityType == 'Container'"
                    >check_circle_outline</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
            </v-card>

            <v-card
              outlined
              @click="
                (manualDocument.quantityType = 'CountContainer'), typeKey++
              "
              class="text-center mt-2"
              style="border: 1px solid grey; border-radius: 20px"
            >
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="blue-grey">widgets</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    Per x
                    {{ item.breakBulkShipment ? "CTO(s)" : "Container(s)" }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <el-input-number
                      :min="1"
                      size="mini"
                      v-model="manualDocument.count"
                    ></el-input-number>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action :key="typeKey">
                  <v-icon
                    color="success"
                    v-if="manualDocument.quantityType == 'CountContainer'"
                    >check_circle_outline</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
            </v-card>

            <v-card
              v-if="!item.breakBulkShipment"
              outlined
              @click="
                (manualDocument.quantityType = 'SpecifyContainer'), typeKey++
              "
              class="text-center mt-2"
              style="border: 1px solid grey; border-radius: 20px"
            >
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="red">widgets</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    Specify Containers
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="typeKey">
                  <v-icon
                    color="success"
                    v-if="manualDocument.quantityType == 'SpecifyContainer'"
                    >check_circle_outline</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-list>
          <v-row justify="center" class="mt-2">
            <v-btn
              v-if="manualDocument.quantityType != 'SpecifyContainer'"
              color="primary"
              text
              @click="createManualDocuments()"
              :loading="creatingManualDocuments"
              >Create Document(s)</v-btn
            >
            <v-btn
              v-else
              color="primary"
              text
              @click="selectContainersModal = true"
              :loading="creatingManualDocuments"
              >Select Container(s)</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bulkUpdateModal" max-width="18vw">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Bulk Update </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="bulkUpdateModal = false" color="primary">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <!--Date picker-->
          <v-date-picker v-model="completedDate" scrollable style="margin: 5px">
            <v-row justify="center" style="margin-top: 4px">
              <v-btn
                text
                color="primary"
                :loading="bulkUpdateLoading"
                @click="bulkUpdateDates"
              >
                Submit
              </v-btn>
            </v-row>
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn
          @click="infoStartGuide"
          icon
          style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0"
          ><v-icon color="primary" size="30">close</v-icon></v-btn
        >
        <v-card-title class="d-flex justify-center">
          Welcome to the Document Management Screen.
        </v-card-title>
        <v-card-text>
          <span>
            <p>
              The purpose of this module is to provide a detailed breakdown of
              all documents for this shipment.
            </p>
            <p>It offers the following information for each document:</p>
            <ul>
              <li>
                Integration or task status, if applicable, along with overall
                document status.
              </li>
              <li>Reference number of the document.</li>
              <li>Responsible party for each document.</li>
              <li>Date of document submission and completion.</li>
              <li>
                Indication if the document is created per shipment or per
                container.
              </li>
              <li>Relevant containers associated with the document.</li>
              <li>Option to include documents in the courier parcel.</li>
              <li>
                Ability to manually add required documents to the shipment by
                selecting the Plus sign. (Ensure to adjust the relevant Shipment
                Profile for future shipments to make sure to include relevant
                documents.)
              </li>
            </ul>
            <p></p>
            <p>Click into any document to create and manage it accordingly.</p>
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectContainersModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Select Containers </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="selectContainersModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-switch
            v-model="manualDocument.individualDocuments"
            color="success"
            label="Create document per container"
          ></v-switch>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="container in bookings"
              :key="container.id"
              @click="addContainer(container.id)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ container.containerNo }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ container.ctoNo }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action :key="manualDocumentContainerKey">
                <v-icon
                  color="green"
                  v-if="
                    manualDocument &&
                    manualDocument.containers &&
                    manualDocument.containers.includes(container.id)
                  "
                  >check_circle_outline</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-row justify="center" class="mt-2">
            <v-btn
              :disabled="
                !manualDocument.containers ||
                manualDocument.containers.length == 0
              "
              color="primary"
              text
              @click="createManualDocuments()"
              :loading="creatingManualDocuments"
              >Create Document(s)</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="previewFileCoverModal"
      width="1200px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card
        v-if="
          shipment.shipmentFile &&
          shipment.shipmentFile.fileCover &&
          shipment.shipmentFile.fileCover.name
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ shipment.shipmentFile.fileCover.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn @click="generateCover()" :loading="generatingFileCover" icon
            ><v-icon>refresh</v-icon></v-btn
          >
          <v-btn
            @click="downloadUrlDocument(shipment.shipmentFile.fileCover)"
            icon
            ><v-icon>download</v-icon></v-btn
          >
          <v-btn @click="fullscreen = !fullscreen" icon
            ><v-icon v-if="!fullscreen">fullscreen</v-icon
            ><v-icon v-else>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon text @click="previewFileCoverModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text
          :style="{
            'max-height': fullscreen ? '90vh' : '75vh',
            'overflow-y': 'auto',
          }"
        >
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div
                v-if="
                  shipment.shipmentFile.fileCover.type &&
                  shipment.shipmentFile.fileCover.type.includes('image/')
                "
              >
                <v-img
                  :src="shipment.shipmentFile.fileCover.url"
                  contain
                  style="max-height: 70vh"
                  @load="imageLoading = false"
                >
                </v-img>
              </div>
              <div
                v-else-if="
                  shipment.shipmentFile.fileCover.type == 'application/pdf'
                "
              >
                <v-row justify="center">
                  <v-col
                    cols="12"
                    sm="9"
                    class="text-center"
                    v-if="shipment.shipmentFile.fileCover.url"
                  >
                    <div ref="pdfBox">
                      <VuePdfEmbed
                        :width="fullscreen ? 1200 : 900"
                        :source="shipment.shipmentFile.fileCover.url"
                        @loaded="imageLoading = false"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span
                      >This file is in a format that cannot be previewed.</span
                    >
                    <br /><br />
                    <v-btn
                      color="primary"
                      style="text-transform: none"
                      @click="
                        downloadUrlDocument(shipment.shipmentFile.fileCover)
                      "
                    >
                      <v-icon class="mr-1">download</v-icon> Download</v-btn
                    >
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="documentProcessingOfficeModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Select an Address</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="documentProcessingOfficeModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item v-if="addresses.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No addresses found</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="address in addresses"
              :key="address.id"
              @click="setDocumentAddess(address)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ address.alias }}</v-list-item-title>
                <!-- <v-list-item-subtitle>{{ address.city }}, {{ address.state }} {{ address.zip }}</v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="packingListMailingDialog"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Send Packing List</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="openPackingListMail()"
            :disabled="packingListItems.length <= 0"
            ><v-icon small>arrow_forward</v-icon></v-btn
          >
          <v-btn text @click="packingListMailingDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item
              v-for="item in packingListItems"
              :key="item.bookingContainer.ctoNo"
            >
              <v-list-item-action>
                <v-checkbox v-model="item.selected" color="blue"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="item.bookingContainer.containerNo">{{
                  item.bookingContainer.containerNo
                }}</v-list-item-title>
                      <v-list-item-title v-else>{{
                  item.bookingContainer.ctoNo
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="openPackingListMailBody"
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="creatingEmails">
        <v-card-text>
          <v-toolbar flat>
            <v-toolbar-title
              >Packing List : {{ fileNumber }} - {{ shipment.vesselName }}
              {{ shipment.voyage }} -
              {{ shipment.consignee.name }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              :loading="creatingEmails"
              @click="sendPackingListMail"
              color="blue"
              ><v-icon small>mail</v-icon>
            </v-btn>
            <v-btn text @click="openPackingListMailBody = false">X</v-btn>
          </v-toolbar>
          <v-row>
            <v-col>
              <v-text-field
                label="Email Adresses"
                class="ml-3"
                outlined
                hint="Please seperate email addresses with a comma (,)"
                v-model="packingListEmailAdresses"
              ></v-text-field>
              <v-text-field
                label="CC Email Adresses"
                class="ml-3"
                outlined
                hint="Please seperate email addresses with a comma (,)"
                v-model="packingListCCEmailAdresses"
              ></v-text-field>
              <v-text-field
                label="Reply Email Adresses"
                class="ml-3"
                outlined
                hint="Please seperate email addresses with a comma (,)"
                v-model="packingReplyAdresses"
              ></v-text-field>
              <v-text class="ml-3 hint-text" style="color: red">
                {{ replayAddr() }}
              </v-text>
              <!-- <v-text 
                v-if="packingReplyAddresses && packingListCCEmailAddresses"
                class="ml-3 hint-text">
                ** Reply adresses will be used.
                </v-text> 
                <v-text 
                v-if="packingReplyAddresses && !packingListCCEmailAddresses"
                class="ml-3 hint-text">
                ** Reply adresses will be used.
                </v-text> 
                <v-text 
                v-if="!packingReplyAddresses && packingListCCEmailAddresses"
                class="ml-3 hint-text">
                ** CC adresses will be used for reply adresses.
                </v-text> 
                <v-text 
              v-else-if="!packingReplyAddresses && !packingListCCEmailAddresses"
              class="ml-3 hint-text">
              ** No reply addresses will be added if CC and Reply fields are empty.
            </v-text> -->
            </v-col>
          </v-row>
          <p class="ml-2"><strong>Dispatch ID's</strong></p>
          <v-divider color="orange" class="mb-3"></v-divider>
          <v-row>
            <v-col cols="12">
              <div
                v-for="item in packingListItems"
                :key="item.bookingContainer.ctoNo"
              >
                <p class="ml-2">
                  {{ item.bookingContainer.ctoNo }} -
                  {{ item.bookingContainer.containerNo }} -

                  {{
                    bookings
                      .find(
                        (booking) =>
                          booking.ctoNo == item.bookingContainer.ctoNo
                      )
                      .containerPallets.filter(
                        (pallet) =>
                          pallet.containerId == item.bookingContainer.id
                      )
                      .map((pallet) => pallet.sscc)
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      ).length
                  }}
                  plts -
                  {{
                    bookings
                      .find(
                        (booking) =>
                          booking.ctoNo == item.bookingContainer.ctoNo
                      )
                      .containerPallets.filter(
                        (pallet) =>
                          pallet.containerId == item.bookingContainer.id
                      )
                      .map((pallet) => parseInt(pallet.noCartons))
                      .reduce((a, b) => a + b, 0)
                  }}
                  ctns
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="documentUserAssign"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="savingDocumentationUser">
        <v-toolbar flat>
          <v-toolbar-title>Assign Documentation User</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="documentUserAssign = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            prepend-inner-icon="search"
            v-model="searchUser"
            clearable
            placeholder="Search"
          ></v-text-field>
          <v-list dense style="max-height: 60vh; overflow-y: auto">
            <v-list-item v-if="filterUsers.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey">No users found</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="user in filterUsers"
              :key="user.id"
              @click="assignDocumentUser(user)"
            >
              <v-list-item-action>
                <v-avatar :color="user.user.avatar ? 'white' : 'secondary'">
                  <v-img
                    v-if="user.user.avatar"
                    :src="user.user.avatar"
                    contain
                  ></v-img>
                  <h3 v-else>{{ user.user.firstname.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  >{{ user.user.firstname }}
                  {{ user.user.surname }}</v-list-item-title
                >
                <v-list-item-subtitle v-if="user.position">
                  <v-icon small color="grey" class="mr-2">work</v-icon>
                  {{ user.position }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import DocumentModal from "@/components/SystemComponents/DocumentModal.vue";
import FormModal from "@/components/SystemComponents/FormModal.vue";
import pdf from "vue-pdf";
import TaskModal from "../SystemComponents/TaskModal.vue";

import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
// import steps from "./Steps/shipmentDocumentsSteps";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  props: [
    "actions",
    "bookings",
    "documents",
    "item",
    "tasks",
    "taskUpdateKey",
    "documentUpdateKey",
    "shipment",
    "uploads",
    "events",
    "documentTypes",
    "relationships",
    "fileNumber",
    "loadingDocuments",
    "userScope",
  ],
  components: {
    DocumentModal,
    FormModal,
    pdf,
    TaskModal,
    VuePdfEmbed,
  },
  data: () => ({
    steps: null,
    packingReplyAdresses: "",
    creatingEmails: false,
    packingListEmailAdresses: "",
    packingListCCEmailAdresses: "",
    openPackingListMailBody: false,
    packingListMailingDialog: false,
    packingListItems: [],
    bulkUpdateModal: false,
    bulkUpdateArray: [],
    actionKey: 3000,
    addDocumentModal: false,
    addresses: [],
    attachmentKey: 1000,
    attachmentItem: {},
    addAttachmentModal: false,
    additionalDocumentHeaders: [
      {
        text: "Country",
        value: "iso2",
        align: "center",
      },
    {
        text: "Type",
        value: "type",
        align: "left",
      },
      {
        text: "Name",
        value: "name",
        align: "left",
      },
      {
        text: "Integration",
        value: "integrationCode",
        align: "left",
      },
      {
        text: "Regulatory Body",
        value: "regulatoryBody.name",
        align: "left",
      },
      {
        text: "Description",
        value: "description",
        align: "left",
      },
    ],
    completedDate: null,
    completedDateModal: false,
    countryDocuments: [],
    coverKey: 100,
    creatingManualDocuments: false,
    deletingServiceFailure: false,
    documentProcessingOfficeModal: false,
    displayedSubtasks: [],
    documentModal: false,
    documentUserAssign: false,
    documentItem: {},
    documentKey: 0,
    documentTab: "info",
    docLoading: null,
    documentLog: [],
    documentUserKey: 2200,
    previewLoading: null,
    expandedProfile: false,
    expandedTask: null,
    form: {},
    formPreview: false,
    formKey: 4000,
    fullscreen: false,
    loadingUsers: false,
    documentResponsibleParties: [
      { id: 1, name: "Buyer", value: "buyer" },
      { id: 2, name: "Forwarder", value: "forwarder" },
      { id: 3, name: "Shipper", value: "shipper" },
      { id: 4, name: "Consignee", value: "consignee" },
    ],
    generatingFileCover: false,
    groupedHeader: ["locationDocument.rank", "locationDocumentId"],
    headers: [
      {
        text: "",
        value: "bulkUpdate",
        align: "center",
        width: "50px",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        width: "75px",
      },
      {
        text: "Task Status",
        value: "integrationStatus",
        align: "center",
        width: "75px",
        groupable: false,
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        width: "50px",
      },
      {
        text: "Name",
        value: "locationDocument.name",
        align: "left",
        groupable: false,
      },

      // {
      //   text: "Billable",
      //   value: "isBillable",
      //   align: "center",
      //   groupable: false,
      // },
      {
        text: "Reference",
        value: "reference",
        align: "center",
        groupable: false,
      },
      {
        text: "Responsible Party",
        value: "responsiblePartyType",
        align: "center",
        groupable: false,
      },

      {
        text: "Submitted Date",
        value: "submittedDate",
        align: "center",
        sortable: false,
      },
      {
        text: "Completed Date",
        value: "completedDate",
        align: "center",
        sortable: false,
      },
      // {
      //   text: "Tasks",
      //   value: "tasks",
      //   align: "center",
      //   sortable: false,
      // },

      {
        text: "Rank",
        value: "locationDocument.rank",
        align: "center",
        groupable: false,
        width: "50px",
      },
      // {
      //   text: "Regulatory Body",
      //   value: "locationDocument.regulatoryBody.name",
      //   align: "left",
      //   groupable: false,
      // },

      // {
      //   text: "Responsible Party",
      //   value: "responsiblePartyType",
      //   align: "center",
      //   width: "50px",
      // },
      // {
      //   text: "Quantity",
      //   value: "quantity",
      //   align: "center",
      //   width: "50px",
      // },
      {
        text: "Container(s)",
        value: "bookingContainer.containerNo",
        align: "center",
        sortable: false,
      },
      {
        text: "Courier",
        value: "courier",
        align: "center",
        width: "50px",
      },
    ],
    imageLoading: false,
    bulkUpdateLoading: false,
    loadingAction: null,
    loadingAdditionalDocuments: false,
    loadingAttachments: [],
    loadingDocumentLog: false,
    loadingServiceFailures: false,
    loadingTaskActions: [],
    manualDocumentContainerKey: 9000,
    previewFileCoverModal: false,
    tableBillableStates: {},
    tableDeleteStates: {},
    manualDocument: {},
    massActions: [],
    openKey: 5000,
    orgUsers: [],
    previewItem: {},
    previewModal: false,
    pageCount: [],
    responsibleParties: [
      "LCL",
      "Client",
      "Shipping Line",
      "Other",
      "PPECB",
      "DALLRD",
    ],
    savingDate: false,
    savingDocument: false,
    savingDocumentAttachment: false,
    savingDocumentLogItem: [],
    savingDocumentationUser: false,
    search: null,
    searchUser: null,
    searchAdditionalDocuments: null,
    selectContainersModal: false,
    selectedTaskButton: [],
    selectVariationModal: false,
    serviceFailureCategories: [],
    serviceFailures: [],
    serviceFailureModal: false,
    serviceFailureItem: {},
    savingServiceFailure: false,
    setQuantityModal: false,
    showDetails: [],
    startingAction: false,
    statusOptions: [
      "Not Required",
      "Pending",
      "In Progress",
      "Submitted",
      "Resubmitted",
      "Complete",
    ],
    statusKey: 5000,
    statusStepper: 3,
    subtaskKey: 6000,
    submittedDateModal: false,
    taskModal: false,
    task: {},
    taskActionKey: 2000,
    typeKey: 1000,
    infoStart: false,
  }),
  watch: {
    packingReplyAdresses: {
      handler(val) {
        if (val) {
          this.replayAddr();
        }
      },
    },
    packingListCCEmailAdresses: {
      handler(val) {
        if (val) {
          this.replayAddr();
        }
      },
    },
    documentUpdateKey() {
      if (this.documentModal) {
        this.viewDocument(this.documentItem);
      }
    },
    "item.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getMassActions(val);
        }
      },
    },
    "item.courierParty": {
      immediate: true,
      handler(val) {
        if (!val) {
          let headerItem = this.headers.findIndex((x) => x.value == "courier");
          this.headers[headerItem].text = '"Copy To" Document';
        } else {
          let headerItem = this.headers.findIndex((x) => x.value == "courier");
          this.headers[headerItem].text = "Courier Document";
        }
        // this.documentUpdateKey++;
      },
    },
  },
  async created() {
    this.getServiceFailureCategories();
    this.loadOtherDocuments();
    this.getOrganisationAddresses();
    this.getOrganisationUsers();
    await this.getSteps();
  },
  async mounted() {
    this.driver = new Driver({
      animate: false,
    });
    this.bulkUpdateArray = [];
  },
  computed: {
    filterUsers() {
      let result = this.orgUsers;
      if (this.searchUser) {
        result = result.filter(
          (x) =>
            x.user.firstname
              .toLowerCase()
              .includes(this.searchUser.toLowerCase()) ||
            x.user.surname
              .toLowerCase()
              .includes(this.searchUser.toLowerCase()) ||
            x.user.emailAddress
              .toLowerCase()
              .includes(this.searchUser.toLowerCase()) ||
            x.user.authProvider
              .toLowerCase()
              .includes(this.searchUser.toLowerCase())
        );
      }
      return result;
    },
    showBulkUpdate(items) {
      console.log("this.bulkUpdateArray", this.bulkUpdateArray);
      if (this.bulkUpdateArray.length === 0) return false;

      const selectedLocationDocumentId =
        this.bulkUpdateArray[0].locationDocument.id;
      return this.documents.some(
        (doc) => doc.locationDocument.id === selectedLocationDocumentId
      );
    },
    selectedBulkUpdateDates() {
      console.log(
        "this.bulkUpdateArray in selectedBulkUpdateDates",
        this.bulkUpdateArray
      );
      if (this.bulkUpdateArray && this.bulkUpdateArray.length > 0) {
        const firstItem = this.documents.find(
          (doc) =>
            doc.locationDocument.id ===
            this.bulkUpdateArray[0].locationDocument.id
        );
        return firstItem ? this.getGroupName(firstItem) : null;
      }
      return null;
    },
    availableParties() {
      let result = [];
      let parties = [
        "shipper",
        "onBehalfShipper",
        "forwarder",
        "onBehalfForwarder",
        "consignee",
        "onBehalfConsignee",
        "buyer",
        "onBehalfBuyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
      ];
      for (let i = 0; i < parties.length; i++) {
        if (this.item[parties[i]]) {
          let findExisting = result.find(
            (x) => x.value == this.item[parties[i] + "Id"]
          );
          if (!findExisting) {
            result.push({
              name: this.item[parties[i]].name,
              value: this.item[parties[i] + "Id"],
            });
          }
        }
      }
      if (this.relationships && this.relationships.length > 0) {
        for (let i = 0; i < this.relationships.length; i++) {
          let findExisting = result.find(
            (x) => x.value == this.relationships[i].customerId
          );
          if (!findExisting) {
            result.push({
              name: this.relationships[i].customer.name,
              value: this.relationships[i].customerId,
            });
          }
        }
      }
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      result.push({ name: "Other", value: null });
      return result;
    },
    availableConsigneeAddresses() {
      let result = [];
      if (this.item.consignee) {
        result.push({
          name: this.item.consignee.name,
          value: "consignee",
        });
      }
      if (this.item.onBehalfConsignee) {
        result.push({
          name: this.item.onBehalfConsignee.name,
          value: "onBehalfConsignee",
        });
      }
      return result;
    },
    availableShipperAddresses() {
      let result = [];
      if (this.item.shipper) {
        result.push({
          name: this.item.shipper.name,
          value: "shipper",
        });
      }
      if (this.item.onBehalfShipper) {
        result.push({
          name: this.item.onBehalfShipper.name,
          value: "onBehalfShipper",
        });
      }
      return result;
    },
    availableStatuses() {
      let options = this.statusOptions.filter((x) => x != "Not Required");
      return options;
    },

    documentContainer() {
      let result = [];
      if (this.documentItem && this.documentItem.id) {
        let containers = this.documentItem.shipmentDocumentTypeContainers.map(
          (x) => x.bookingContainerId
        );
        result = this.bookings.filter((x) => containers.includes(x.id));
      }
      return result;
    },
    documentTypeDocuments() {
      let result = [];
      if (this.documentItem && this.documentItem.id) {
        let linkedDocuments =
          this.documentItem.shipmentDocumentTypeDocuments.map(
            (x) => x.shipmentDocumentId
          );
        result = this.uploads.filter((x) => linkedDocuments.includes(x.id));
      }
      return result;
    },
    documentTypeSupportingDocuments() {
      let result = [];
      if (this.documentItem && this.documentItem.id) {
        let variationDocuments = [].concat.apply(
          [],
          this.documentItem.variations.map(
            (x) => x.shipmentDocumentTypeSupportings
          )
        );
        variationDocuments = variationDocuments.map(
          (x) => x.shipmentDocumentId
        );
        result = this.uploads.filter((x) => variationDocuments.includes(x.id));
      }
      return result;
    },
    taskDocuments() {
      let result = [];
      if (this.documentItem && this.documentItem.id) {
        let taskAttachments = this.documentTasks(this.documentItem);
        result = [].concat.apply(
          [],
          taskAttachments.map((x) => x.taskAttachments)
        );
      }

      result = [...result, ...this.supportingDocuments];
      return result;
    },

    supportingDocuments() {
      let result = [];
      if (this.documentItem && this.documentItem.id) {
        let supportingDocuments =
          this.documentItem.shipmentDocumentTypeSupportings
            .filter((x) => x.shipmentDocumentId)
            .map((x) => x.shipmentDocumentId);
        let variations = this.documentItem.variations;
        let variationSupportingDocuments = [].concat.apply(
          [],
          variations.map((x) =>
            x.shipmentDocumentTypeSupportings
              .filter((y) => y.shipmentDocumentId)
              .map((y) => y.shipmentDocumentId)
          )
        );
        supportingDocuments = [
          ...supportingDocuments,
          ...variationSupportingDocuments,
        ];
        result = this.uploads.filter((x) => supportingDocuments.includes(x.id));
      }

      return result;
    },
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'ShipmentSummary/Document/Steps',
        });
        this.steps = steps.steps
        },
    replayAddr() {
      if (this.packingReplyAdresses && this.packingListCCEmailAdresses) {
        return "** Reply adresses will be used for replying on messages.";
      } else if (
        this.packingReplyAdresses &&
        !this.packingListCCEmailAdresses
      ) {
        return "** Reply adresses will be used for replying on messages.";
      } else if (
        !this.packingReplyAdresses &&
        this.packingListCCEmailAdresses
      ) {
        return "** CC adresses will be used for reply adresses.";
      } else if (
        !this.packingReplyAdresses &&
        !this.packingListCCEmailAdresses
      ) {
        return "** No reply addresses will be added if CC or Reply fields are empty.";
      }
    },
    async sendPackingListMail() {
      this.creatingEmails = true;
      let obj = {
        containerData: this.packingListItems.map((x) => {
          return {
            ctoNo: x.bookingContainer.ctoNo,
            containerNo: x.bookingContainer.containerNo,
            plts: this.bookings
              .find((booking) => booking.ctoNo == x.bookingContainer.ctoNo)
              .containerPallets.filter(
                (pallet) => pallet.containerId == x.bookingContainer.id
              )
              .map((pallet) => pallet.sscc)
              .filter((value, index, self) => self.indexOf(value) === index)
              .length,
            ctns: this.bookings
              .find((booking) => booking.ctoNo == x.bookingContainer.ctoNo)
              .containerPallets.filter(
                (pallet) => pallet.containerId == x.bookingContainer.id
              )
              .map((pallet) => parseInt(pallet.noCartons))
              .reduce((a, b) => a + b, 0),
          };
        }),
        ctoNos: this.packingListItems.map((x) => x.bookingContainer.ctoNo),
        containerNos: this.packingListItems.map(
          (x) => x.bookingContainer.containerNo
        ),
        shipmentDocumentTypes: this.packingListItems.map((x) => x.id),
        emailAddresses: this.packingListEmailAdresses,
        ccAddresses: this.packingListCCEmailAdresses,
        replyAddresses: this.packingReplyAdresses,
        title: `Packing List : ${this.fileNumber} - ${this.shipment.vesselName} ${this.shipment.voyage} - ${this.shipment.consignee.name}`,
      };

      let response = await this.$API.sendPackingListMail(obj);
      if (response.status === 200) {
        this.$message.success(response.message);
        this.openPackingListMailBody = false;
      } else {
        this.$message.error(response.message);
        this.openPackingListMailBody = false;
      }
      this.creatingEmails = false;
    },
    openPackingListMail() {
      this.packingListItems = this.packingListItems.filter((x) => x.selected);
      console.log("this.packingListItems", this.packingListItems);
      this.packingListMailingDialog = false;
      this.openPackingListMailBody = true;
    },
    openPackingListDialog(items) {
      this.packingListItems = items.filter(
        (x) =>
          x.locationDocument &&
          x.locationDocument.id === 217 &&
          x.status === "Complete"
      );
      this.packingListItems.forEach((x) => (x.selected = true));
      this.packingListMailingDialog = true;
    },
    checkBulkDateStatus(item) {
      // console.log('item',item);
      if (
        (item.status == "Submitted" || item.status == "Resubmitted") &&
        (this.selectedBulkUpdateDates === null ||
          this.selectedBulkUpdateDates === this.getGroupName(item))
      ) {
        return true;
      }
      return false;
    },
    async bulkUpdateDates() {
      this.bulkUpdateLoading = true;
      let obj = {
        completedDate: this.completedDate,
        shipmentDocumentIds: this.bulkUpdateArray.map((x) => x.id),
        shipmentId: this.shipment.id,
      };

      try {
        let res = await this.$API.bulkUpdateShipmentDocuments(obj);
        this.$message.success("Successfully updated!");
        this.bulkUpdateModal = false;
        this.bulkUpdateLoading = false;
        //Emit function issue currently
        this.bulkUpdateArray = [];
        this.$emit("refreshDocuments");
        // this.$emit('refreshActions')
      } catch (e) {
        this.$message.error("An error ocurred");
        this.bulkUpdateArray = [];
        this.bulkUpdateLoading = false;
        console.log(e);
      }
    },
    getGroupName(item) {
      return item.locationDocument.parentTypeDocument
        ? item.locationDocument.parentTypeDocument.name
        : item.locationDocument.name;
    },
    actionRequired(task) {
      let result = false;
      if (this.documents && this.documents.length > 0) {
        let findDoc = this.documents.find(
          (x) =>
            (x.id === task.shipmentDocumentTypeId ||
              x.id === task.parentShipmentDocumentId) &&
            x.shipmentActionId
        );
        if (findDoc) {
          if (findDoc.shipmentActionId) {
            let findAction = this.actions.find(
              (x) => x.id === findDoc.shipmentActionId
            );
            result = findAction ? findAction.status != "Complete" : true;
          }
        }
      }
      return result;
    },
    actionRequiredData(task) {
      let result = false;
      if (this.documents && this.documents.length > 0) {
        let findDoc = this.documents.find(
          (x) =>
            (x.id === task.shipmentDocumentTypeId ||
              x.id === task.parentShipmentDocumentId) &&
            x.shipmentActionId
        );
        if (findDoc) {
          if (findDoc.shipmentActionId) {
            let findAction = this.actions.find(
              (x) => x.id === findDoc.shipmentActionId
            );
            result = findAction;
          }
        }
      }
      return result;
    },
    getAddressAlias(id) {
      if (id) {
        let find = this.addresses.find((x) => x.id == id);
        if (find) {
          return find.alias;
        } else return "Select Document Processing Office";
      } else {
        return "Select Document Processing Office";
      }
    },
    getDocumentUserDescription(user) {
      if (user) {
        return user.firstname + " " + user.surname;
      } else {
        return "Assign Document User";
      }
    },
    async setDocumentResponsibleParty(party, document) {
      this.loadingDocuments = true;
      document.responsiblePartyType = party;
      await this.$API.updateShipmentDocumentType(document);
      this.$message({
        type: "success",
        message: "Successfully updated!",
      });
      this.loadingDocuments = false;
    },
    addAttachment(task) {
      this.attachmentItem = {
        task: task,
      };
      this.addAttachmentModal = true;
    },
    addContainer(id) {
      let index = this.manualDocument.containers.indexOf(id);
      if (index == -1) {
        this.manualDocument.containers.push(id);
      } else {
        this.manualDocument.containers.splice(index, 1);
      }
      this.manualDocumentContainerKey++;
    },
    addManualDocument() {
      this.manualDocument = {
        shipmentId: this.shipment.id,
        count: 1,
        quantityType: "Shipment",
        containers: [],
        individualDocuments: false,
      };
      this.addDocumentModal = true;
    },
    addServiceFailure() {
      this.serviceFailureItem = {
        responsibleOrganisationId: this.$store.state.currentOrg.id,
      };
      this.serviceFailureModal = true;
    },
    availableActions(task) {
      return task && task.taskActions
        ? task.taskActions.filter((x) => x.statuses.includes(task.status))
        : [];
    },
    availableAttachmentDocuments() {
      let result = [];
      if (this.attachmentItem && this.attachmentItem.task) {
        result = this.attachmentItem.task.taskAttachments
          ? this.attachmentItem.task.taskAttachments.filter((x) => !x.file)
          : [];
      }

      return result;
    },
    calculateProgress(item) {
      let options = [
        "Open",
        "In Progress",
        "Pending Draft Approval",
        "Draft Approved",
        "Submitted",
        "Pending Attestation",
        "Attest Complete",
        "Complete",
      ];
      if (!item.approvalRequired) {
        options = options.filter(
          (x) => x != "Pending Draft Approval" && x != "Draft Approved"
        );
      }
      return options.indexOf(item.status) + 1 + "/" + options.length;
    },
    async createManualDocuments() {
      try {
        this.creatingManualDocuments = true;
        let result = await this.$API.createManualShipmentDocument(
          this.manualDocument
        );
        this.$emit("refreshDocuments");
        this.$emit("refreshTasks");
        this.creatingManualDocuments = false;
        this.setQuantityModal = false;
        this.addDocumentModal = false;
        this.selectContainersModal = false;
        this.$emit("refreshActions");
        this.getMassActions(this.shipment.id);
        this.manualDocument = {};
        this.$message.success("Successfully added document!");
      } catch (e) {
        this.$message.error("An error ocurred");
        this.creatingManualDocuments = false;
      }
    },
    documentMedia(document) {
      let result = [];
      let variationDocuments = [].concat.apply(
        [],
        document.variations.map((x) => x.shipmentDocumentTypeSupportings)
      );
      variationDocuments = variationDocuments.map((x) => x.shipmentDocumentId);
      let supportingDocuments = document.shipmentDocumentTypeSupportings
        .filter((x) => x.shipmentDocumentId)
        .map((x) => x.shipmentDocumentId);
      let variations = document.variations;
      let variationSupportingDocuments = [].concat.apply(
        [],
        variations.map((x) =>
          x.shipmentDocumentTypeSupportings
            .filter((y) => y.shipmentDocumentId)
            .map((y) => y.shipmentDocumentId)
        )
      );

      let taskAttachments = this.documentTasks(document);
      taskAttachments = [].concat.apply(
        [],
        taskAttachments.map((x) => x.taskAttachments)
      );
      taskAttachments = this.uploads
        .filter((x) => taskAttachments.map((y) => y.file).includes(x.key))
        .map((x) => x.id);
      supportingDocuments = [
        ...variationDocuments,
        ...supportingDocuments,
        ...variationSupportingDocuments,
        ...taskAttachments,
      ];
      result = this.uploads.filter((x) => supportingDocuments.includes(x.id));
      return result;
    },
    documentsRequired(task) {
      let requiredDocs = task.taskAttachments.filter(
        (x) => x.required && !x.file
      );
      let existingDocs = task.taskAttachments.filter((x) => x.file);
      let findMatches = requiredDocs.filter((x) =>
        existingDocs.map((y) => y.name).includes(x.name)
      );
      return (
        (task.attachmentRequired &&
          task.taskAttachments.filter((x) => x.file).length == 0) ||
        (requiredDocs.length > 0 && findMatches.length == 0)
      );
    },
    async deleteAttachment(item, task) {
      this.$confirm(
        "Are you sure you want to delete this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          item.isDeleted = true;
          item.isActive = false;
          await this.$API.deleteTaskAttachment(item);
          let index = task.taskAttachments.findIndex((x) => x.id == item.id);
          task.taskAttachments.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    deleteServiceFailure() {
      this.deletingServiceFailure = true;
      this.$confirm(
        "Are you sure you want to delete this service failure?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.serviceFailureItem.isDeleted = true;
          this.serviceFailureItem.isActive = false;
          await this.$API.updateServiceFailure(this.serviceFailureItem);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.getDocumentServiceFailures(this.documentItem);
          this.serviceFailureModal = false;
          this.serviceFailureItem = {};
          this.deletingServiceFailure = false;
        })
        .catch(() => {
          this.deletingServiceFailure = false;
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    documentTasks(document) {
      let variations = document.variations
        ? document.variations.map((x) => x.id)
        : [];
      let ids = [document.id, ...variations];
      let tasks = this.tasks.filter(
        (x) =>
          ids.includes(x.shipmentDocumentTypeId) ||
          ids.includes(x.parentShipmentDocumentId)
      );
      return tasks;
    },
    async deleteDocument(item) {
      this.$confirm(
        "Are you sure you want to delete this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          item.isDeleted = true;
          item.isActive = false;
          await this.$API.updateShipmentDocument(item);
          let index = this.uploads.findIndex((x) => x.id == item.id);
          this.uploads.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Delete canceled",
          });
        });
    },
    async downloadUrlDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    async downloadDocument(doc) {
      this.docLoading = doc.id;
      this.documentKey++;
      if (!doc.fullFile) {
        let file = await this.$API.downloadFile({
          key: doc.file,
        });
        doc.fullFile = file.file;
        doc.fileType = file.fileType;
      }

      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.fullFile;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
      this.docLoading = null;
      this.documentKey++;
    },
    editServiceFailure(failure) {
      this.serviceFailureItem = failure;
      this.serviceFailureModal = true;
    },
    async executeAction(action, task) {
      this.loadingAction = action.code + "_" + task.id;
      if (task.documentationCode) {
        if (task.responseData) {
          task.responseData.taskId = this.task.id;
        } else {
          task.payload.taskId = this.task.id;
        }
        let result = await this.$API.executeDocument(action.code, task.id, {
          ...this.task.payload,
          ...this.task.responseData,
        });
        task = { ...task, ...result };
      } else if (task.integrationCode) {
        if (task.responseData) {
          task.responseData.taskId = this.task.id;
        } else {
          task.payload.taskId = this.task.id;
        }
        let result = await this.$API.executeIntegration(action.code, task.id, {
          ...task.payload,
          ...task.responseData,
        }, {
            type: 'action'
          });
        task = { ...task, ...result };
      }
      this.loadingAction = null;
      setTimeout(() => {
        this.getTaskAttachments(task);
      }, 2000);
      // this.loadTaskLogs();
      this.getTaskActions(task);
    },
    completeForm(task) {
      if (this.documentTypes && this.documentTypes.length > 0) {
        let flattened = [
          ...this.documentTypes,
          ...[].concat.apply(
            [],
            this.documentTypes.map((x) => x.variations)
          ),
        ];
        let findDoc = flattened.find(
          (x) =>
            (x.id == task.shipmentDocumentTypeId ||
              x.id == task.parentShipmentDocumentId) &&
            x.shipmentActionId
        );
        if (findDoc) {
          if (findDoc.shipmentActionId) {
            let findAction = this.actions.find(
              (x) => x.id === findDoc.shipmentActionId
            );
            this.form = findAction;
            this.form.form.formResponse = findAction.response;
            this.formPreview = true;
            this.formKey++;
          }
        }
      }
    },
    formComplete(form) {
      let find = this.actions.find((x) => x.id == form.shipmentActionId);
      return find ? find.status == "Complete" : false;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async generateCover() {
      try {
        this.generatingFileCover = true;
        let result = await this.$API.generateShipmentFileCover(
          this.shipment.id
        );
        if (result && result.key) {
          this.shipment.shipmentFile.fileCover = result;
          this.shipment.shipmentFile.fileCoverId = result.id;
        }
        this.generatingFileCover = false;
        this.coverKey++;
        this.$message.success("File cover generated successfully!");
        this.previewFileCoverModal = true;
      } catch (e) {
        console.log(e);
        this.generatingFileCover = false;
        this.$message.error(
          "Oops! an error occurred while genrating the cover"
        );
      }
    },
    async getOrganisationUsers() {
      this.loadingUsers = true;
      this.orgUsers = await this.$API.getOrganisationUsers();
      this.loadingUsers = false;
    },
    async getOrganisationAddresses() {
      this.addresses = await this.$API.getOrganisationAddress();
      // this.userAddress = await this.$API.getOrganisationUserAddress()
    },
    getShipmentDocuments(event) {
      this.$emit("getShipmentDocuments", event);
    },
    getDocumentIcon(name) {
      let type = name.split(".");
      let result = null;
      if (name.includes(".") && type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return result ? `/icons/${result}.svg` : null;
    },
    getInspectionColor(status) {
      switch (status) {
        case null:
          return "orange";
        case "New":
          return "blue";
        case "Resubmitted":
          return "blue";
        case "Approved":
          return "green";
        case "Rejected":
          return "red";
      }
    },
    getIcon(type) {
      switch (type.toLowerCase()) {
        case "buyer":
          return "shopping_cart";
        case "consignee":
          return "call_received";
        case "shipper":
          return "directions_boat";
        case "forwarder":
          return "fast_forward";
        case "notify party":
          return "notifications";
      }
    },
    findMassAction(items) {
      let findTasks = this.tasks.filter(
        (x) =>
          items.map((y) => y.id).includes(x.shipmentDocumentTypeId) ||
          items.map((y) => y.id).includes(x.parentShipmentDocumentId)
      );
      let codes = findTasks.map((x) => x.integrationCode).filter(Boolean);
      let result = this.massActions.find((x) => codes.includes(x.code));
      return result ?? null;
    },
    async getMassActions(id) {
      this.massActions = await this.$API.getShipmentMassActions(id);
    },
    async startMassAction(action) {
      this.startingAction = true;
      await this.$API.startMassAction(this.shipment.id, action.code);
      let index = this.massActions.indexOf(action);
      this.massActions.splice(index, 1);
      this.$message.success("Successfully started tasks");
      this.startingAction = false;
    },
    async getServiceFailureCategories() {
      this.serviceFailureCategories =
        await this.$API.getServiceFailureCategories();
    },
    // async getDocumentLog(id) {
    //   this.loadingDocumentLog = true
    //   this.documentLog = await this.$API.getShipmentDocumentLog(id)
    //   this.loadingDocumentLog = false
    // },
    async getDocumentServiceFailures(document) {
      this.loadingServiceFailures = true;
      this.serviceFailures =
        await this.$API.getServiceFailureByShipmentDocument(document.id);
      this.loadingServiceFailures = false;
    },
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "orange";
        case "open":
          return "grey";
        case "not required":
          return "grey";
        case "not started":
          return "grey";
        case "cancelled":
          return "grey";
        case "approved":
          return "green";
        case "complete":
          return "green";
        case "delivered":
          return "green";
        case "in progress":
          return "indigo";
        case "created":
          return "blue";
        case "submitted":
          return "blue";
        case "resubmitted":
          return "cyan";
        case "draft":
          return "orange";
        case "file generated":
          return "orange";
        case "pending":
          return "orange";
        case status.includes("pending"):
          return "orange";
        case "draft approved":
          return "purple";
        case "revision required":
          return "red";
        case "rejected":
          return "red";
        case "query":
          return "purple";
        case "in transnit":
          return "orange";
        case "failed":
          return "red";
        case "new":
          return "amber";
        case "error":
          return "red";
        case "not created":
          return "red";
        case "in transit":
          return "blue";
        case "validation failed":
          return "red";
        case "inspection failed":
          return "red";
        case "inspection approved":
          return "success";
        case status.toLowerCase().includes("pending"):
          return "orange";
        case status.toLowerCase().includes("approved"):
          return "green";
        case status.toLowerCase().includes("failed"):
          return "red";
        case status.toLowerCase().includes("revoked"):
          return "red";
        default:
          return "grey";
      }
    },
    getStatusIcon(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "warning";
        case "not required":
          return "close";
        case "cancelled":
          return "cancel";
        case "not started":
          return "schedule";
        case "pending":
          return "pending";
        case "in progress":
          return "sync";
        case "draft":
          return "edit_note";
        case "created":
          return "mark_email_read";
        case "submitted":
          return "mark_email_read";
        case "resubmitted":
          return "mark_email_read";
        case "complete":
          return "task_alt";
        case "delivered":
          return "task_alt";
        case "file generated":
          return "task";
        case "new":
          return "star";
        case "error":
          return "cancel";
        case "failed":
          return "cancel";
        case "rejected":
          return "cancel";
        case "not created":
          return "error";
        case "approved":
          return "fact_check";
        case "validation failed":
          return "error";
        case "inspection failed":
          return "error";
        case "inspection approved":
          return "fact_check";
        case "in transit":
          return "local_shipping";
        case status.toLowerCase().includes("pending"):
          return "pending";
        case status.toLowerCase().includes("approved"):
          return "fact_check";
        case status.toLowerCase().includes("failed"):
          return "error";
        case status.toLowerCase().includes("revoked"):
          return "error";
        default:
          return "info";
      }
    },
    async getTaskActions(task) {
      if(task.id){
        this.loadingTaskActions.push(task.id);
      task.taskActions = [];
      task.taskActions = await this.$API.getTaskActions(task.id);
      this.actionKey++;
      this.loadingTaskActions.splice(
        this.loadingTaskActions.indexOf(task.id),
        1
      );
      }
      
    },
    getTaskStatus(task) {
      switch (task.status) {
        case "New":
          return { name: task.status, icon: "grade", color: "orange" };
        case "Not started":
          return { name: task.status, icon: "schedule", color: "grey" };
        case "Pending":
          return { name: task.status, icon: "pending", color: "orange" };
        case "Resubmitted":
          return { name: task.status, icon: "sync", color: "orange" };
        case "In Progress":
          return { name: task.status, icon: "sync", color: "blue" };
        case "Complete":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "File Generated":
          return { name: task.status, icon: "task", color: "orange" };
        case "Success":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "Cancelled":
          return { name: task.status, icon: "cancel", color: "grey" };
        case "Not Created":
          return { name: task.status, icon: "error", color: "red" };
        case "Rejected":
          return { name: task.status, icon: "cancel", color: "red" };
        case "Draft":
          return { name: task.status, icon: "edit_note", color: "orange" };
        case "Error":
          return { name: task.status, icon: "error", color: "red" };
        case "Failed":
          return { name: task.status, icon: "cancel", color: "red" };
        case "Submitted":
          return { name: task.status, icon: "mark_email_read", color: "blue" };
        case "Not Required":
          return { name: task.status, icon: "close", color: "grey" };
        case "Approved":
          return { name: task.status, icon: "fact_check", color: "green" };
        case "Validation Failed":
          return { name: task.status, icon: "error", color: "red" };
        default:
          return {
            icon: "info",
            color: "grey",
            name: task.status,
          };
      }
    },
    async getTaskAttachments(task) {
      this.loadingAttachments.push(task.id);
      task.taskAttachments = await this.$API.getTaskAttachments(task.id);
      this.attachmentKey++;
      this.loadingAttachments.splice(
        this.loadingAttachments.indexOf(task.id),
        1
      );
    },
    async loadOtherDocuments() {
      if (this.shipment.originCountryId) {
        this.loadingAdditionalDocuments = true;
         let exportDocuments = await this.$API.getCountryDocuments(
          this.shipment.originCountryId
        );
        exportDocuments.forEach(x=>{
          x.iso2 = this.shipment.originCountry.iso2
        })
        let importDocuments = await this.$API.getCountryDocuments(
          this.shipment.destinationCountryId
        );
        importDocuments.forEach(x=>{
          x.iso2 = this.shipment.destinationCountry.iso2
        })
        this.countryDocuments = [...exportDocuments, ...importDocuments];
        this.loadingAdditionalDocuments = false;
      }
    },
    async nextStatus(item) {
      let index = this.availableStatuses.indexOf(item.status);
      if (this.availableStatuses.length > index + 1) {
        item.status = this.availableStatuses[index + 1];
        index = this.availableStatuses.indexOf(item.status);
        this.statusStepper = index + 1;
      }
      await this.$API.updateShipmentDocumentType({
        id: item.id,
        status: item.status,
      });
    },
    async previewDocument(doc) {
      this.previewLoading = doc.id;
      this.documentKey++;
      this.previewItem = this.documents.find((x) => x.key == doc.file);
      if (!this.previewItem) {
        this.previewItem = {};
      }
      if (!this.previewItem.fullFile) {
        let file = await this.$API.downloadFile({
          key: doc.file,
        });
        this.previewItem.fullFile = file.file;
        this.previewItem.fileType = file.fileType;
      }

      this.previewModal = true;
      this.previewLoading = null;
      this.documentKey++;
    },
    async saveAttachment() {
      this.savingDocumentAttachment = true;
      this.previewLoading = null;
      this.docLoading = false;
      let obj = this.attachmentItem.taskAttachment;
      delete obj.id;
      delete obj.createdAt;
      delete obj.updatedAt;
      obj.file = this.attachmentItem.file;
      await this.$API.createTaskAttachment(obj);
      this.getTaskAttachments(this.attachmentItem.task);
      this.addAttachmentModal = false;
      this.attachmentItem = {};
      this.savingDocumentAttachment = false;
    },
    async saveDocument() {
      this.savingDocument = true;
      await this.$API.updateShipmentDocumentType(this.documentItem);
      this.$emit("updateDocument", this.documentItem);
      this.documentModal = false;
      this.documentItem = {};
      this.savingDocument = false;
    },
    async saveServiceFailure() {
      this.savingServiceFailure = true;
      if (this.serviceFailureItem.id) {
        await this.$API.updateServiceFailure(this.serviceFailureItem);
      } else {
        this.serviceFailureItem.type = "document";
        this.serviceFailureItem.shipmentDocumentTypeId = this.documentItem.id;
        this.serviceFailureItem.shipmentId = this.documentItem.shipmentId;
        await this.$API.createServiceFailure(this.serviceFailureItem);
      }
      this.getDocumentServiceFailures(this.documentItem);
      this.serviceFailureModal = false;
      this.serviceFailureItem = {};
      this.savingServiceFailure = false;
    },
    selectManualDocument(item) {
      this.manualDocument = {
        ...this.manualDocument,
        parentShipmentDocumentId: item.id,
        parentShipmentDocument: item,
        subtypes: item.locationDocumentSubtypes,
      };
      if (this.manualDocument.subtypes.length == 1) {
        this.manualDocument.locationDocument = this.manualDocument.subtypes[0];
        this.manualDocument.locationDocumentId =
          this.manualDocument.subtypes[0].id;
        // console.log('1: Setting quantity', this.manualDocument)

        this.setQuantityModal = true;
      } else if (this.manualDocument.subtypes.length > 1) {
        this.selectVariationModal = true;
        // console.log('2: Setting quantity', this.manualDocument)
      } else if (this.manualDocument.subtypes.length == 0) {
        delete this.manualDocument.parentShipmentDocumentId;
        delete this.manualDocument.parentShipmentDocument;
        this.manualDocument.locationDocument = item;
        this.manualDocument.locationDocumentId = item.id;
        this.setQuantityModal = true;
        // console.log('3: Setting quantity', this.manualDocument)
      }
    },
    setSubtype(item) {
      this.manualDocument.locationDocumentId = item.id;
      this.manualDocument.locationDocument = item;
      this.selectVariationModal = false;
      this.setQuantityModal = true;
    },
    async startTask(task) {
      task.status = "Pending";

      await this.$API.updateTask({
        id: task.id,
        status: "Pending",
        triggerTime: new Date()
      });
      this.$emit("update", task);
    },
    async startTasks() {
      let tasks = this.documentTasks(this.documentItem);
      tasks = tasks.filter((x) => x.status == "Not started");
      this.documentItem.integrationStatus = "Pending";
      await this.$API.updateShipmentDocumentType({
        id: this.documentItem.id,
        integrationStatus: this.documentItem.integrationStatus,
      });
      await this.$API.startDocumentTasks(this.documentItem.id, {
        status: "Pending",
      });
      for (let i = 0; i < tasks.length; i++) {
        tasks[i].status = "Pending";
      }
    },
    async submitForm(response) {
      this.form.response = response;
      this.form.userId = this.$store.state.user.id;
      this.form.user = this.$store.state.user;
      this.form.status = "Complete";
      await this.$API.submitShipmentActions(this.form);
      this.formPreview = false;
      this.form = {};
    },
    update(doc) {
      this.task = doc;
      let findTask = this.tasks.findIndex((x) => x.id == doc.id);
      if (findTask > -1) {
        this.tasks[findTask] = doc;
        this.statusKey++;
      }
      this.$emit("update", doc);
    },
    async assignDocumentUser(user) {
      this.savingDocumentationUser = true;
      this.shipment.assignedDocumentUserId = user.user.id;
      this.shipment.assignedDocumentUser = user.user;
      this.documentUserKey++;
      await this.$API.updateShipment({
        id: this.shipment.id,
        assignedDocumentUserId: this.shipment.assignedDocumentUserId,
      });
      this.documentUserAssign = false;
      this.$message.success(
        "Successfully updated assigned documentation user!"
      );
      this.savingDocumentationUser = false;
    },
    setDocumentAddess(address) {
      this.shipment.documentProcessingAddressId = address.id;
      this.shipment.documentProcessingAddress = address;
      this.documentProcessingOfficeModal = false;
      this.updateDocumentationAddress();
    },
    async updateDocumentationAddress() {
      await this.$API.updateShipment({
        id: this.shipment.id,
        documentProcessingAddressId: this.shipment.documentProcessingAddressId,
      });
      this.$message.success(
        "Successfully updated document processing address!"
      );
    },
    async refreshDocument(doc) {
      let result = await this.$API.getShipmentDocumentTypeDetail(
        this.documentItem.id
      );
      this.documentItem = { ...this.documentItem, ...result };
    },
    updateDocument(doc) {
      this.documentModal = false;
      this.$emit("updateDocument", doc);
    },
    async updateDocumentBillable(document) {
      try {
        let billable = !document.isBillable;
        this.$set(this.tableBillableStates, document.id, true);
        await this.$API.updateShipmentDocumentType({
          id: document.id,
          isBillable: billable,
        });
        document.isBillable = billable;
        this.$message.success("Document billable status updated!");
        this.$set(this.tableBillableStates, document.id, false);
      } catch (e) {
        this.$set(this.tableBillableStates, document.id, false);
        this.$message.error("Unable to update document billbable status");
      }
    },
    async deleteShipmentDocumentType(item) {
      this.$confirm(
        "Are you sure you want to delete this document? All work on it will be lost.",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.$set(this.tableDeleteStates, item.id, true);
          item.isDeleted = true;
          item.isActive = false;
          await this.$API.updateShipmentDocumentType(item);
          let index = this.documents.findIndex((x) => x.id == item.id);
          this.documents.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.$set(this.tableDeleteStates, item.id, false);
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Delete canceled",
          });
        });
    },
    async updateStatus(status) {
      this.documentItem.status = status;
      if (status == "Resubmitted") {
        this.addServiceFailure();
      }
      let index = this.availableStatuses.indexOf(status);
      this.statusStepper = index + 1;
      await this.$API.updateShipmentDocumentType({
        id: this.documentItem.id,
        status: this.documentItem.status,
      });
      // this.getDocumentLog(this.documentItem.id)
    },
    async updateDocumentLogItem(item) {
      this.savingDocumentLogItem.push(item.id);
      await this.$API.updateShipmentDocumentTypeLog(item);
      this.savingDocumentLogItem.splice(
        this.savingDocumentLogItem.indexOf(item.id),
        1
      );
    },
    async updateCompletedDate() {
      this.savingDate = true;
      await this.$API.updateShipmentDocumentType({
        id: this.documentItem.id,
        completedDate: this.documentItem.completedDate,
      });
      this.completedDateModal = false;
      this.$message.success("Completed date updated!");
      this.savingDate = false;
    },
    async updateSubmissionDate() {
      this.savingDate = true;
      await this.$API.updateShipmentDocumentType({
        id: this.documentItem.id,
        submittedDate: this.documentItem.submittedDate,
      });
      this.submittedDateModal = false;
      this.$message.success("Submitted date updated!");
      this.savingDate = false;
    },
    viewDocument(document) {
      this.documentLog = [];
      this.documentItem = this.documents.find((x) => x.id == document.id);
      this.documentTab = "info";
      this.expandedProfile = false;
      this.expandedTask = null;
      // this.statusStepper = this.availableStatuses.indexOf(document.status) + 1;
      for (let i = 0; i < this.documentTasks(document).length; i++) {
        this.getTaskAttachments(this.documentTasks(document)[i]);
        this.getTaskActions(this.documentTasks(document)[i]);
      }
      this.documentModal = true;
      setTimeout(() => {
        this.openKey++;
      }, 250);
      // this.getDocumentServiceFailures(document);
      // this.getDocumentLog(document.id)
    },
    viewSubtasks(task) {
      let findIndex = this.displayedSubtasks.findIndex((x) => x == task.id);
      if (findIndex > -1) {
        this.displayedSubtasks.splice(findIndex, 1);
      } else {
        this.displayedSubtasks.push(task.id);
      }
      this.subtaskKey++;
    },
    viewTask(task) {
      this.task = task;
      this.taskModal = true;
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();
      this.driver.defineSteps(this.steps);
      this.driver.start();
    },
  },
};
</script>
<style scoped>
.activeClass {
}

.v-stepper__step {
  flex: 1;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  z-index: 9999;
  position: absolute;
  width: 100%;
  cursor: pointer;
}

.v-stepper__header {
  height: 80px;
}

.centered-checkbox {
  /* Centering using Flexbox */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  /* Scaling down to make the checkbox smaller */
  transform: scale(0.8);
}
</style>
